











































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  CourseController,
  AttendanceController,
  ClassPeriodController,
} from '@/services/request.service'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import AddRemark from './AddRemark.vue'
import EleStudentDetail from './StudentDetail.vue'
import PreStudentDetail from '@/views/Attendance/KindergartenAtten/components/StudentDetail.vue'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { clearEmptyArray, Debounce } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import { i18n } from '@/i18n/i18n'
import { calcBg, calcSymbol } from '@/utils/utils'
import { statusList } from '@/constant/constant'
import { createPagination } from '@/constant/constant'
import SvgIcon from '@/components/SvgIcon.vue'

const operations = {
  CCAClass: function(condition) {
    return AttendanceController.getCcaClassAttendances(
      condition[0],
      condition[1],
      condition[2],
      condition[3],
      condition[4]
    )
  },
  subjectClass: function(condition) {
    return AttendanceController.getClassAttendances(
      condition[0],
      condition[1],
      condition[2],
      condition[3],
      condition[4]
    )
  },
  homeroom: function(condition) {
    return AttendanceController.getDailyAttendances(
      condition[0],
      condition[1],
      condition[2],
      condition[3],
      condition[4]
    )
  },
  advisory: function(condition) {
    return AttendanceController.getDailyAttendances(
      condition[0],
      condition[1],
      condition[2],
      condition[3],
      condition[4]
    )
  },
  couseAttendance: function(condition) {
    return AttendanceController.updateClassAttendance(condition)
  },
  dailyAttendance: function(condition) {
    return AttendanceController.updateDailyAttendance(condition)
  },
  couseAttendanceBatch: function(condition) {
    return AttendanceController.updateClassAttendanceBatch(condition)
  },
  dailyAttendanceBatch: function(condition) {
    return AttendanceController.updateDailyAttendanceBatch(condition)
  },
  CCAClassDropDown: function(date) {
    return CourseController.getCoursesOptionsCascadeForAttendance(date, '1002')
  },
  subjectClassDropDown: function(date) {
    return CourseController.getCoursesOptionsCascadeForAttendance(date, '1001')
  },
  advisoryDropDown: function() {
    return DropDownController.getHouseGroupList()
  },
  mainClassDropDown: function() {
    return AttendanceController.getClassList()
  },
  advisoryWeekly: function(condition) {
    return AttendanceController.getWeeklyAttendances(
      condition[0],
      condition[1],
      condition[2],
      condition[3]
    )
  },
  subjectClassWeekly: function(condition) {
    return AttendanceController.getWeeklyClassAttendances(
      condition[0],
      condition[1],
      condition[2],
      condition[3],
      condition[4]
    )
  },
}
@Component({
  components: {
    AddRemark,
    EleStudentDetail,
    PreStudentDetail,
    TableTotal,
    FlexTooltip,
    filterGroup,
    SvgIcon,
  },
})
export default class AttendanceViewer extends Vue {
  private periods: Array<any> = []
  private originCourses: Array<any> = []
  private filter: any = {
    date: moment(),
    subjectClass: undefined,
    advisory: undefined,
    homeroom: undefined,
    CCAClass: undefined,
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private data: Array<any> = []
  private courses: Array<any> = []
  private remarkVisible = false
  private record: any = {}
  private studentId = 0
  private isVocation = false
  private currentPeriod = ''
  private lastPeriod = ''
  private studentName = ''
  private detailVisible = false
  private confirmVisible = false
  private dateStart = moment()
  private pmVisible = false
  private amVisible = false
  private options = []
  private moment = moment
  private weekViewer: boolean = false
  private dynamicHeader: any = []
  private windowH: any = 696
  private calcBg = calcBg
  private calcSymbol = calcSymbol

  private get statusList(): any {
    return this.$store.state.attendanceStatusList.length
      ? this.$store.state.attendanceStatusList
      : statusList
  }
  private studentCampus = ''
  private studentAttenType = 'full_day'
  private detailModify = false

  @Watch('detailVisible')
  private onVisChange(val) {
    if (!val && this.detailModify) {
      this.refresh()
    }
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get type(): any {
    let tmpType = this.$route.params.attendanceType
    if (!tmpType) return ''
    this.reset()
    this.$route.meta.routerName = `${tmpType}Attendance`
    return tmpType
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get isCourse(): any {
    if (!this.type) return false
    this.$nextTick(() => {
      if (this.$route.query.date) {
        this.filter.date = moment(Number(this.$route.query.date))
      }
      this.getDropDownInfo(this.filter.date)
    })
    return ['subjectClass', 'CCAClass'].includes(this.type)
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    let weekCol = [
      {
        key: 'name',
        title: this.$t('attendance.name'),
        ellipsis: true,
        width: 220,
        fixed: 'left',
        scopedSlots: { customRender: 'fixedName' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'gender',
        width: 70,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('myClass.student.gender'),
        scopedSlots: { customRender: 'fixedGender' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'className',
        width: 80,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('attendance.class'),
        scopedSlots: { customRender: 'commonFix' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'studentNum',
        width: 100,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('attendance.studentId'),
        scopedSlots: { customRender: 'commonFix' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
    ]
    if (this.weekViewer) {
      let dynamicCol = this.dynamicHeader.map(item => ({
        dataIndex: item.key,
        key: item.key,
        align: 'center',
        date: moment(item.date),
        title: `${moment(item.date).format('MM.DD')}\n${
          this.isCourse ? item.classPeriod : item.isAm ? 'AM' : 'PM'
        }`,
        scopedSlots: { customRender: 'dynamicColumns' },
        customHeaderCell: () => ({ style: { whiteSpace: 'pre-line' } }),
        customCell: (record, index) => {
          return {
            style: { background: calcBg(record[item.key]), cursor: 'pointer' },
            class: 'dynamic-cell',
          }
        },
      }))
      weekCol = weekCol.concat(dynamicCol)
    }
    return this.weekViewer
      ? weekCol
      : [
          {
            key: 'name',
            title: this.$t('attendance.name'),
            ellipsis: true,
            scopedSlots: { customRender: 'name' },
          },
          {
            dataIndex: 'gender',
            width: 130 * this.ratio,
            title: this.$t('myClass.student.gender'),
            scopedSlots: { customRender: 'gender' },
          },
          {
            dataIndex: 'className',
            key: 'class',
            width: this.isCourse ? 130 * this.ratio : 200 * this.ratio,
            ellipsis: true,
            title: this.$t('attendance.class'),
          },
          {
            dataIndex: 'studentNo',
            key: 'studentId',
            width: 150 * this.ratio,
            title: this.$t('attendance.studentId'),
          },
          this.isCourse
            ? -1
            : [
                {
                  key: 'amAttendance',
                  slots: { title: 'am' },
                  width: this.isCourse ? 80 * this.ratio : 230 * this.ratio,
                  scopedSlots: { customRender: 'amAttendance' },
                },
                // {
                //   key: 'amRemark',
                //   width: 120 * this.ratio,
                //   title: this.$t('attendance.amRemark'),
                //   scopedSlots: { customRender: 'amRemark' },
                // },
                {
                  key: 'pmAttendance',
                  slots: { title: 'pm' },
                  width: this.isCourse ? 80 * this.ratio : 230 * this.ratio,
                  scopedSlots: { customRender: 'pmAttendance' },
                },
                {
                  key: 'pmRemark',
                  width: 120 * this.ratio,
                  title: this.$t('attendance.remark'),
                  scopedSlots: { customRender: 'pmRemark' },
                },
              ],
          this.isCourse
            ? [
                // {
                //   key: 'lastAttendance',
                //   width: 100 * this.ratio,
                //   title: this.lastPeriod || this.$t('attendance.lastAttendance'),
                //   scopedSlots: { customRender: 'lastAttendance' },
                // },
                // {
                //   key: 'lastRemark',
                //   width: 100 * this.ratio,
                //   title: this.$t('attendance.remark'),
                //   scopedSlots: { customRender: 'lastRemark' },
                // },
                {
                  key: 'currentAttendance',
                  slots: { title: 'attendance' },
                  width: 250 * this.ratio,
                  scopedSlots: { customRender: 'currentAttendance' },
                },
                {
                  key: 'currentRemark',
                  width: 100 * this.ratio,
                  title: this.$t('attendance.remark'),
                  scopedSlots: { customRender: 'currentRemark' },
                },
              ]
            : -1,
        ]
          .flat()
          .filter(item => item !== -1)
  }

  private get attendanceDisabled(): any {
    // || moment(this.filter.date).endOf('day') > moment().endOf('day')
    return (
      !this.operationAuths.includes('2007') ||
      !this.data.length ||
      this.isVocation ||
      this.filter.date.isAfter(moment().endOf('day'))
    )
  }

  private created() {
    this.getDateStart()
    // this.getDropDownInfo(this.filter.date)
  }

  private getDropDownInfo(date) {
    const type = this.type
    const isCourse = this.isCourse
    if (['class', 'noAuth'].includes(this.type)) return
    operations[`${type}DropDown`](isCourse ? date.valueOf() : undefined).then(res => {
      if (type !== this.type) return
      if (type === 'advisory') {
        this.options = res.data
      } else {
        this.options = clearEmptyArray(res.data)
      }
      if (!isCourse) {
        if (this.$route.query.id) {
          if (type === 'advisory') {
            this.filter[type] = this.$route.query.id
          } else {
            res.data.forEach(item => {
              item.subOptions.forEach(it => {
                if (it.key === this.$route.query.id) {
                  this.filter[type] = [item.key, it.key]
                }
              })
            })
          }
        } else {
          this.filter[type] =
            type === 'advisory'
              ? res.data[0].houseGroups[0].houseGroupId
              : [res.data?.[0]?.key, res.data?.[0]?.subOptions?.[0]?.key]
        }
      } else {
        if (this.$route.query.id) {
          if (type === 'subjectClass') {
            res.data.forEach(item => {
              item.subOptions.forEach(it => {
                if (it.key === this.$route.query.id) {
                  // this.filter[type] = [item.key, it.key]
                  it.subOptions.forEach(itt => {
                    if (itt.key === this.$route.query.periodId) {
                      this.filter[type] = [item.key, it.key, itt.key]
                    }
                  })
                }
              })
            })
          } else {
            this.filter[type] = [this.$route.query.id, this.$route.query.periodId]
          }
        }
      }
      this.weekViewer ? this.getWeekData() : this.getData()
    })
  }

  private getDateStart(): any {
    AttendanceController.getEditStart()
      .then(res => {
        this.dateStart = moment(res.data)
      })
      .catch(err => console.log(err))
  }

  private disabledDate(current): any {
    return (
      current &&
      (current < moment(this.dateStart).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private selectDate(value): void {
    if (this.isCourse) {
      this.filter[this.type] = undefined
      this.getDropDownInfo(value)
    } else {
      this.weekViewer ? this.getWeekData() : this.getData()
    }
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    const { date, courseId } = this.filter
    let id = this.filter[this.type]
    if (!id) return
    this.loading = true
    let condition = [] as any
    if (this.isCourse) {
      condition = [
        date.valueOf(),
        this.type === 'subjectClass' ? id[1] : id[0],
        this.type === 'subjectClass' ? id[2] : id[1],
        page.current,
        page.pageSize,
      ]
    } else {
      condition = [
        date.valueOf(),
        page.current,
        page.pageSize,
        this.type === 'homerrom' ? undefined : id,
        this.type === 'homeroom' ? id[1] : undefined,
      ]
    }
    operations[this.type](condition)
      .then(res => {
        this.isVocation = false
        if (this.type === 'subjectClass') {
          this.currentPeriod = res.data.currentPeriod
          this.lastPeriod = res.data.lastPeriod
          this.data = res.data.attendances.items.map(item => {
            if (item.status === 'weekendHoliday') {
              this.isVocation = true
            }
            return {
              ...item,
              am: item.am === 'weekendHoliday' ? 'holiday' : item.am,
              pm: item.pm === 'weekendHoliday' ? 'holiday' : item.pm,
              status: item.status === 'weekendHoliday' ? 'holiday' : item.status,
              amDisabled: item.am === 'weekendHoliday',
              pmDisabled: item.pm === 'weekendHoliday',
            }
          })
          this.pagination.total = res.data.attendances.totalItem
        } else {
          this.data = res.data.items.map(item => {
            if (
              ((item.am === 'weekendHoliday' || item.pm === 'weekendHoliday') &&
                this.type !== 'CCAClass') ||
              (item.status === 'weekendHoliday' && this.type === 'CCAClass')
            ) {
              this.isVocation = true
            }
            return {
              ...item,
              am: item.am === 'weekendHoliday' ? 'holiday' : item.am,
              pm: item.pm === 'weekendHoliday' ? 'holiday' : item.pm,
              amDisabled: item.am === 'weekendHoliday',
              pmDisabled: item.pm === 'weekendHoliday',
              status: item.status === 'weekendHoliday' ? 'holiday' : item.status,
              stautsDisabled: item.status === 'weekendHoliday',
            }
          })
          this.pagination.total = res.data.totalItem
        }
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.weekViewer ? this.getWeekData(this.pagination) : this.getData(this.pagination)
  }

  private refresh(): void {
    this.weekViewer ? this.getWeekData(this.pagination) : this.getData(this.pagination)
  }

  private setAttendance(key, studentId, type): void {
    const request = this.isCourse
      ? {
          classArrangeId: this.data[0].classArrangeId,
          studentId,
          status: key,
        }
      : {
          date: moment(this.filter.date).valueOf(),
          type,
          studentId,
          status: key,
        }
    operations[this.isCourse ? 'couseAttendance' : 'dailyAttendance'](request).then(res => {
      let data = cloneDeep(this.data)
      data.forEach(item => {
        if (item.studentId === studentId) {
          item[type] = key
        }
      })
      this.data = cloneDeep(data)
    })
  }

  private setWeekAttendance(key, record, colKey): void {
    const day = this.dynamicHeader.find(item => item.key === colKey)
    const studentId = record.studentId
    const request = this.isCourse
      ? {
          classArrangeId: day.classArrangeId,
          studentId,
          status: key,
        }
      : {
          date: day.date,
          type: day.isAm ? 'am' : 'pm',
          studentId,
          status: key,
        }
    operations[this.isCourse ? 'couseAttendance' : 'dailyAttendance'](request)
      .then(res => {
        this.$message.success(this.$tc('common.attendanceSuccess'))
        // record[colKey] = key
        this.getWeekData(this.pagination, false)
      })
      .catch(err => console.log(err))
  }

  private setAttendanceBatch(type): void {
    const request = this.isCourse
      ? {
          classArrangeId: this.data[0].classArrangeId,
          studentIds: this.data.map(item => item.studentId),
          status: 'intime',
        }
      : {
          date: moment(this.filter.date).valueOf(),
          type,
          students: this.data.map(item => item.studentId),
          status: 'intime',
        }
    operations[this.isCourse ? 'couseAttendanceBatch' : 'dailyAttendanceBatch'](request)
      .then(res => {
        this.$message.success(this.$tc('common.attendanceSuccess'))
      })
      .finally(() => this.refresh())
  }

  private addRemark(record, filed): void {
    this.record = record
    // 2020/11/06 李沁颖要改的 未考勤能写评语
    if (!this.isCourse) {
      this.$set(record, 'amOrPm', filed)
    }
    this.remarkVisible = true
  }

  private viewDetail(studentId, studentName, campus, attendanceType): void {
    this.studentCampus = campus
    this.studentAttenType = attendanceType
    this.$nextTick(() => {
      this.studentId = studentId
      this.studentName = studentName
      this.detailVisible = true
      this.detailModify = false
    })
  }

  private confirmVisibleChange(visible): void {
    if (!visible) {
      this.confirmVisible = false
      return
    }
    if (this.operationAuths.includes('2007')) {
      this.confirmVisible = true
    }
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private reset() {
    this.data = []
    this.weekViewer = false
    this.currentPeriod = ''
    this.lastPeriod = ''
    this.studentCampus = '1230'
    this.studentAttenType = 'full_day'
    this.filter = {
      date: moment(),
      subjectClass: undefined,
      advisory: undefined,
      homeroom: undefined,
      CCAClass: undefined,
    }
    this.pagination = createPagination({
      showTotal: total => i18n.t('common.pageTotal', { total }),
    })
    this.options = []
  }

  private pmVisibleChange(visible): void {
    if (!visible) {
      this.pmVisible = false
      return
    }
    if (this.$store.state.operationAuths.includes('2007')) {
      this.pmVisible = true
    }
  }

  private amVisibleChange(visible): void {
    if (!visible) {
      this.amVisible = false
      return
    }
    if (this.$store.state.operationAuths.includes('2007')) {
      this.amVisible = true
    }
  }

  private getWeekData(page = { current: 1, pageSize: 50 }, clear: any = true): void {
    if (clear) {
      this.data = []
      this.dynamicHeader = []
    }
    const id = this.filter[this.type]
    let condition = [] as any
    if (this.isCourse) {
      if (!id) return
      condition = [
        this.filter.date.valueOf(),
        this.type === 'subjectClass' ? id[1] : id[0],
        this.type === 'subjectClass' ? id[2] : id[1],
        page.current,
        page.pageSize,
      ]
    } else {
      condition = [this.filter.date.valueOf(), page.current, page.pageSize, id]
    }
    this.loading = true
    operations[`${this.type}Weekly`](condition)
      .then(res => {
        this.dynamicHeader = res.data.weekDays || res.data.classDays
        this.data = res.data.attendances.items.map(item => ({
          ...item,
          ...item.attendanceStatusMap,
        }))
        this.pagination.total = res.data.attendances.totalItem
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private changeViewer(): void {
    this.weekViewer = !this.weekViewer
    this.weekViewer ? this.getWeekData() : this.getData()
  }

  private mounted(): any {
    setTimeout(() => {
      this.windowH = (this.$refs.attendanceViewer as Element)?.getBoundingClientRect().height || 696
    }, 0)

    window.addEventListener('resize', this.onWindowResize)
    this.$once('hook:beforeDestory', () => {
      window.removeEventListener('resize', this.onWindowResize)
    })
  }

  @Debounce(500)
  private onWindowResize(): void {
    this.windowH = (this.$refs.attendanceViewer as Element)?.getBoundingClientRect().height || 696
  }

  private getPopupContainer(el, dialogContext) {
    let node = el
    while (node.className !== 'attendance-viewer' && node.localName !== 'body') {
      node = node.parentNode
    }
    return node
    // if (node.className === 'attendance-viewer') {
    //   return node
    // } else {
    //   return document.body
    // }
  }
}
