

































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, GradeController } from '@/services/request.service'
import { getSchoolInfo, clearEmptyArray } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { createPagination } from '@/constant/constant'
import { toPage, guid, judgeVnode } from '@/utils/utils'
import { i18n } from '@/i18n/i18n'
import debounce from 'lodash/debounce'
import { exportXlsx, writeFile } from '@/utils/xlsx'

import moment from 'moment'
@Component({
  components: {
    filterGroup,
    FlexTooltip,
  },
})
export default class EGradeReport extends Vue {
  private filter: any = {
    schoolYearId: undefined,
    gradePeriodId: 'all',
    subjectIds: [],
    classId: [],
    studentName: '',
    studentNum: '',
  }

  private sectionIds: any = []
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private exportLoading: boolean = false
  private data: Array<any> = []

  private schoolYearList: any = []
  private gradePeriods: Array<any> = []
  private homerooms: Array<any> = []
  private subjects: Array<any> = []

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('myClass.name'),
        dataIndex: 'name',
        width: 270 * this.ratio,
        fixed: 'left',
      },
      {
        key: 'studentNum',
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 100 * this.ratio,
        fixed: 'left',
        ellipsis: true,
      },
      {
        key: 'grade',
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        // scopedSlots: { customRender: 'creater' },
        width: 50 * this.ratio,
      },
      {
        key: 'homeroom',
        dataIndex: 'homeroom',
        title: this.$t('common.homeroom'),
        width: 70 * this.ratio,
      },
      {
        key: 'schoolYear',
        dataIndex: 'schoolYear',
        title: this.$t('common.schoolYear'),
        width: 100 * this.ratio,
        ellipsis: true,
      },
      {
        key: 'gradePeriod',
        dataIndex: this.locale === 'zh' ? 'gradePeriod' : 'gradePeriodEN',
        title: this.$t('myClass.student.gradePeriod'),
        width: 200 * this.ratio,
        ellipsis: true,
      },
      {
        title: this.$t('common.academicAchieve'),
        children: [
          {
            key: 'achievementM',
            dataIndex: 'achievementM',
            title: 'Total M',
            width: 80 * this.ratio,
          },
          {
            key: 'achievementAM',
            dataIndex: 'achievementAM',
            title: 'Total AM',
            width: 80 * this.ratio,
          },
          {
            key: 'achievementP',
            dataIndex: 'achievementP',
            title: 'Total P',
            width: 80 * this.ratio,
          },
          {
            key: 'achievementAP',
            dataIndex: 'achievementAP',
            title: 'Total AP',
            width: 80 * this.ratio,
          },
          {
            key: 'achievementBP',
            dataIndex: 'achievementBP',
            title: 'Total BP',
            width: 80 * this.ratio,
          },
          {
            key: 'achievementNC',
            dataIndex: 'achievementNC',
            title: 'Total NC',
            width: 80 * this.ratio,
          },
        ],
      },
      {
        title: this.$t('common.learningEfforts'),
        children: [
          {
            key: 'behaviorE',
            dataIndex: 'behaviorE',
            title: 'Total E',
            width: 80 * this.ratio,
          },
          {
            key: 'behaviorG',
            dataIndex: 'behaviorG',
            title: 'Total G',
            width: 80 * this.ratio,
          },
          {
            key: 'behaviorS',
            dataIndex: 'behaviorS',
            title: 'Total S',
            width: 80 * this.ratio,
          },
          {
            key: 'behaviorN',
            dataIndex: 'behaviorN',
            title: 'Total N',
            width: 80 * this.ratio,
          },
          {
            key: 'behaviorNC',
            dataIndex: 'behaviorNC',
            title: 'Total NC',
            width: 80 * this.ratio,
          },
        ],
      },
    ].filter(item => item)
  }

  private get excelColumns(): Array<any> {
    return [
      {
        title: this.$t('myClass.name'),
        dataIndex: 'name',
        width: 30,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 20,
      },
      {
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        width: 10,
      },
      {
        dataIndex: 'homeroom',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        dataIndex: 'schoolYear',
        title: this.$t('common.schoolYear'),
        width: 20,
      },
      {
        dataIndex: this.locale === 'zh' ? 'gradePeriod' : 'gradePeriodEN',
        title: this.$t('myClass.student.gradePeriod'),
        width: 30,
      },
      {
        key: 'achievementM',
        dataIndex: 'achievementM',
        title: 'Total M',
        width: 10,
      },
      {
        key: 'achievementAM',
        dataIndex: 'achievementAM',
        title: 'Total AM',
        width: 10,
      },
      {
        key: 'achievementP',
        dataIndex: 'achievementP',
        title: 'Total P',
        width: 10,
      },
      {
        key: 'achievementAP',
        dataIndex: 'achievementAP',
        title: 'Total AP',
        width: 10,
      },
      {
        key: 'achievementBP',
        dataIndex: 'achievementBP',
        title: 'Total BP',
        width: 10,
      },
      {
        key: 'achievementNC',
        dataIndex: 'achievementNC',
        title: 'Total NC',
        width: 10,
      },
      {
        key: 'behaviorE',
        dataIndex: 'behaviorE',
        title: 'Total E',
        width: 10,
      },
      {
        key: 'behaviorG',
        dataIndex: 'behaviorG',
        title: 'Total G',
        width: 10,
      },
      {
        key: 'behaviorS',
        dataIndex: 'behaviorS',
        title: 'Total S',
        width: 10,
      },
      {
        key: 'behaviorN',
        dataIndex: 'behaviorN',
        title: 'Total N',
        width: 10,
      },
      {
        dataIndex: 'behaviorNC',
        title: 'Total NC',
        width: 10,
      },
    ].filter(item => item)
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    this.data = []
    let { schoolYearId, gradePeriodId, subjectIds, classId, studentName, studentNum } = this.filter
    let condition = {
      schoolYearId,
      gradePeriodId: gradePeriodId === 'all' ? undefined : gradePeriodId,
      subjectIds: subjectIds.length ? subjectIds : undefined,
      classId: classId.length ? classId : undefined,
      studentName: studentName.trim() ? studentName.trim() : undefined,
      studentNum: studentNum.trim() ? studentNum.trim() : undefined,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    this.loading = true
    GradeController.getPrimaryGradeSummary(condition)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private refresh(): void {
    this.getData(this.pagination)
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSubjectList(),
      DropDownController.getClassByCampus('1231'),
      DropDownController.getSchoolYearRuleList(),
    ])
      .then(res => {
        this.subjects = res[0].data
        this.homerooms = clearEmptyArray(res[1].data)
        console.log(this.homerooms)
        this.schoolYearList = res[2].data
        if (this.homerooms.length && this.schoolYearList[0]) {
          this.$set(this.filter, 'schoolYearId', this.schoolYearList[0].key)
          // this.$set(this.filter, 'classId', [this.homerooms[0].key, this.homerooms[0].subOptions[0].key])
          this.getGradePeriods(this.schoolYearList[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getGradePeriods(schoolYearId): void {
    this.gradePeriods = []
    DropDownController.getGradePeriods(schoolYearId)
      .then(res => {
        this.gradePeriods = res.data
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeYear(): void {
    const { schoolYearId } = this.filter
    this.getGradePeriods(schoolYearId)
  }

  private reset(): void {
    this.filter = {
      schoolYearId: this.schoolYearList[0].key,
      gradePeriodId: 'all',
      subjectIds: [],
      classId: [],
      studentName: '',
      studentNum: '',
    }
    this.changeYear()
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(this.pagination)
  }

  private async exportExcel(): Promise<void> {
    this.exportLoading = true

    let { schoolYearId, gradePeriodId, subjectIds, classId, studentName, studentNum } = this.filter
    let condition = {
      schoolYearId,
      gradePeriodId,
      subjectIds: subjectIds.length ? subjectIds : undefined,
      classId: classId.length ? classId : undefined,
      studentName,
      studentNum,
      pageCurrent: 1,
      pageSize: 0,
    }
    const res = await GradeController.getPrimaryGradeSummary(condition)

    const file = exportXlsx(this.excelColumns, res.data.items)
    const ws = file.getWorksheet(1)

    let firstRow = [
      this.$t('myClass.name'),
      this.$t('common.studentId'),
      this.$t('common.grade'),
      this.$t('common.homeroom'),
      this.$t('common.schoolYear'),
      this.$t('myClass.student.gradePeriod'),
      this.$t('common.academicAchieve'),
      '',
      '',
      '',
      '',
      '',
      this.$t('common.learningEfforts'),
      '',
      '',
      '',
      '',
    ]

    ws.insertRow(1, firstRow)
    ws.mergeCells('A2:A1')
    ws.mergeCells('B2:B1')
    ws.mergeCells('C2:C1')
    ws.mergeCells('D2:D1')
    ws.mergeCells('E2:E1')
    ws.mergeCells('F2:F1')
    ws.mergeCells('G1:L1')
    ws.mergeCells('M1:Q1')

    ws.eachRow(row => {
      row.height = 30
      row.eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
      })
    })

    await writeFile(file, 'Elementary Grading Form.xlsx')
    this.exportLoading = false
  }
}
