var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"attendanceViewer",staticClass:"attendance-viewer"},[_c('div',{staticClass:"filter-container"},[_c('filter-group',[_c('div',{staticClass:"filter-item"},[_c('span',{staticClass:"filter-text"},[_vm._v(_vm._s(_vm.$t('attendance.date')))]),_c('a-date-picker',{staticClass:"filter-input",attrs:{"allowClear":false,"disabledDate":_vm.disabledDate},on:{"change":function () { return _vm.getRightData(); }},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1),_c('div',{staticClass:"filter-item"},[_c('span',{staticClass:"filter-text"},[_vm._v(_vm._s(_vm.$t('dormitory.studyRoom')))]),_c('a-select',{staticClass:"filter-input",attrs:{"showSearch":"","placeholder":_vm.$t('common.select')},on:{"change":function (e) { return (_vm.weekViewer ? _vm.getWeekData() : _vm.getData()); }},model:{value:(_vm.filter.selfStudyRoomId),callback:function ($$v) {_vm.$set(_vm.filter, "selfStudyRoomId", $$v)},expression:"filter.selfStudyRoomId"}},_vm._l((_vm.studyRoomList),function(item){return _c('a-select-option',{key:item.key},[_vm._v(" "+_vm._s(item.value)+" ")])}),1)],1)])],1),_c('div',{staticClass:"button-container"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.changeViewer}},[_c('span',[_vm._v(_vm._s(_vm.$t(("attendance." + (_vm.weekViewer ? 'day' : 'week') + "Viewer"))))])])],1),_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"rowKey":function (record) { return record.studentId; },"scroll":_vm.weekViewer
        ? { x: 80 * (_vm.dynamicHeader || []).length + 120 * 2 + 240, y: _vm.windowH - 216 }
        : { y: _vm.windowH - 216 }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"gender",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? _vm.$t(("common.gender." + text)) : ''))])]}},{key:"name",fn:function(record){return [_c('flex-tooltip',{attrs:{"value":record.studentName,"imgUrl":record.avatarUrl,"showImg":"","canClick":_vm.operationAuths.includes('2008')},on:{"click":function($event){return _vm.viewDetail(
            record.studentId,
            record.studentName,
            record.campusType,
            record.attendanceType
          )}}})]}},{key:"currentRemark",fn:function(record){return [_c('a-tooltip',{attrs:{"title":record.comment,"getPopupContainer":function () { return _vm.$document.body; },"placement":"topRight"}},[_c('a-button',{attrs:{"type":"link","disabled":_vm.attendanceDisabled},on:{"click":function($event){return _vm.addRemark(record, 'status')}}},[_c('a-icon',{style:({ color: record.comment ? '#26b889' : 'rgba(0, 0, 0, 0.12)' }),attrs:{"type":"message"}})],1)],1)]}},{key:"currentAttendance",fn:function(record){return [_c('div',{staticClass:"flex-style align-center"},[_c('a-tooltip',{style:({ visibility: record.leaveInfo ? 'unset' : 'hidden' }),attrs:{"overlayStyle":{ maxWidth: '320px' }},scopedSlots:_vm._u([(record.leaveInfo)?{key:"title",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveTime'))+"：")]),_c('span',[_vm._v(_vm._s(_vm._f("doubleMoment")([record.leaveInfo.startTime, record.leaveInfo.endTime],'YYYY.MM.DD HH:mm')))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveReason'))+"：")]),_c('span',[_vm._v(_vm._s(record.leaveInfo.reason))])])]},proxy:true}:null],null,true)},[_c('svg-icon',{attrs:{"icon":"takeLeave","className":"take-leave-svg"}})],1),_c('a-dropdown',{attrs:{"trigger":['click'],"disabled":_vm.attendanceDisabled || !!record.leaveInfo}},[_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
                  var key = ref.key;

                  return _vm.setAttendance(key, record.studentId, 'status');
          }},slot:"overlay"},_vm._l((_vm.statusList),function(item){return _c('a-menu-item',{key:item,style:({
                backgroundColor: _vm.calcBg(item),
                color: item !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.65)',
              })},[_vm._v(_vm._s(_vm.$t('attendance.statusList.' + item)))])}),1),_c('a-tooltip',{attrs:{"title":record.status ? _vm.$t('attendance.statusList.' + record.status) : _vm.$t('common.select'),"getPopupContainer":function () { return _vm.$document.body; }}},[_c('a-button',{staticClass:"attendance-button",style:({ backgroundColor: _vm.calcBg(record.status) }),attrs:{"disabled":_vm.attendanceDisabled}},[_c('span',{staticClass:"ellipsis",style:({ color: record.status !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.25)' })},[_vm._v(_vm._s(record.status ? _vm.$t('attendance.statusList.' + record.status) : _vm.$t('common.select')))]),_c('a-icon',{attrs:{"type":"down"}})],1)],1)],1)],1)]}},{key:"dynamicColumns",fn:function(text, record, index, col){return [_c('a-dropdown',{attrs:{"getPopupContainer":function () { return _vm.$document.body; },"trigger":['click'],"disabled":!_vm.operationAuths.includes('2007') ||
            _vm.moment(col.date) < _vm.currentSchoolYear.startTime ||
            _vm.moment(col.date).isAfter(_vm.moment().endOf('day'))}},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.calcSymbol(text)))]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
                    var key = ref.key;

                    return _vm.setWeekAttendance(key, record, col.key);
              }},slot:"overlay"},_vm._l((_vm.statusList),function(item){return _c('a-menu-item',{key:item,style:({
              backgroundColor: _vm.calcBg(item),
              color: item !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.65)',
            })},[_vm._v(_vm._s(_vm.$t('attendance.statusList.' + item)))])}),1)],1)]}},{key:"fixedName",fn:function(record){return [_c('div',{staticStyle:{"max-width":"196px"}},[_c('flex-tooltip',{attrs:{"value":record.studentName,"imgUrl":record.avatarUrl,"showImg":"","canClick":_vm.operationAuths.includes('2008')},on:{"click":function($event){return _vm.viewDetail(
              record.studentId,
              record.studentName,
              record.campusType,
              record.attendanceType
            )}}})],1)]}},{key:"fixedGender",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? _vm.$t(("common.gender." + text)) : ''))])]}},{key:"commonFix",fn:function(text){return [_c('div',{staticStyle:{"max-width":"96px"}},[_c('flex-tooltip',{attrs:{"value":text}})],1)]}}])},[_c('template',{slot:"attendance"},[_c('span',{staticClass:"title-tag"},[_vm._v(_vm._s(this.currentPeriod || _vm.$t('attendance.currentAttendance')))]),_c('a-popconfirm',{attrs:{"title":_vm.$t('attendance.batchTip'),"visible":_vm.confirmVisible,"disabled":_vm.attendanceDisabled},on:{"visibleChange":_vm.confirmVisibleChange,"confirm":function($event){return _vm.setAttendanceBatch()}}},[_c('a-button',{attrs:{"type":"primary","size":"small","disabled":_vm.attendanceDisabled}},[_vm._v(_vm._s(_vm.$t('attendance.onTime')))])],1)],1)],2),_c('add-remark',{attrs:{"record":_vm.record,"date":_vm.filter.date,"addType":"studyRoom"},on:{"modify":_vm.refresh},model:{value:(_vm.remarkVisible),callback:function ($$v) {_vm.remarkVisible=$$v},expression:"remarkVisible"}}),(_vm.studentCampus !== '1230')?_c('ele-student-detail',{attrs:{"studentId":_vm.studentId,"studentName":_vm.studentName,"isDay":_vm.studentAttenType === 'full_day'},on:{"echoModify":function (val) { return (_vm.detailModify = val); }},model:{value:(_vm.detailVisible),callback:function ($$v) {_vm.detailVisible=$$v},expression:"detailVisible"}}):_c('pre-student-detail',{attrs:{"studentId":_vm.studentId,"studentName":_vm.studentName},on:{"echoModify":function (val) { return (_vm.detailModify = val); }},model:{value:(_vm.detailVisible),callback:function ($$v) {_vm.detailVisible=$$v},expression:"detailVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }