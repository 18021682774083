import { render, staticRenderFns } from "./StatViewer.vue?vue&type=template&id=4cbe2532&"
import script from "./StatViewer.vue?vue&type=script&lang=ts&"
export * from "./StatViewer.vue?vue&type=script&lang=ts&"
import style0 from "./StatViewer.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports