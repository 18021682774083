





























import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AttendanceStat extends Vue {
  private get menuAuth(): any {
    return this.$store.state.userAuths || {}
  }

  private get type(): any {
    return this.$route.params.statType || ''
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'statViewer') {
      if (
        ![
          'grade',
          'advisory',
          'subjectClass',
          'dormitory',
          'selfStudy',
          'classPeriod',
          'class',
        ].includes(to.params.statType)
      ) {
        this.$router.push({ name: '404' })
      }
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'statViewer', params: { statType: key } }).catch(err => {})
  }
}
