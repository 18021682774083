


















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  AttendanceController,
  CourseController,
  DropDownController,
  GradeController,
  TaskGradeController,
  TeacherController,
} from '@/services/request.service'
import { getSchoolInfo, clearEmptyArray } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { createPagination } from '@/constant/constant'
import { toPage, guid, judgeVnode } from '@/utils/utils'
import { i18n } from '@/i18n/i18n'
import debounce from 'lodash/debounce'
import { exportXlsx, writeFile } from '@/utils/xlsx'

import moment from 'moment'
@Component({
  components: {
    filterGroup,
    FlexTooltip,
  },
})
export default class GradeReport extends Vue {
  private filter: any = {
    schoolYearId: undefined,
    campusId: undefined,
    subjectId: [],
    courseId: undefined,
    classId: undefined,
    houseGroupId: undefined,
    teacherId: undefined,
    studentName: '',
    studentNum: '',
  }

  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private exportLoading: boolean = false
  private data: Array<any> = []
  private dynamicHeader: Array<any> = []

  private schoolYears: any = []
  private subjects: Array<any> = []
  private campuses: Array<any> = []
  private courses: Array<any> = []
  private homerooms: Array<any> = []
  private houses: Array<any> = []
  private teachers: Array<any> = []

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'student',
        title: this.$t('myClass.name'),
        dataIndex: 'student',
        width: 100 * this.ratio,
      },
      {
        key: 'studentNum',
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 60 * this.ratio,
        ellipsis: true,
      },
      {
        key: 'grade',
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        // scopedSlots: { customRender: 'creater' },
        width: 30 * this.ratio,
      },
      {
        key: 'className',
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 50 * this.ratio,
      },
      {
        key: 'advisory',
        dataIndex: 'advisory',
        title: this.$t('common.advisory'),
        width: 70 * this.ratio,
      },
      {
        key: 'subject',
        dataIndex: 'subject',
        title: this.$t('common.subject'),
        width: 70 * this.ratio,
      },
      {
        key: 'course',
        dataIndex: 'course',
        title: this.$t('common.subjectClass'),
        width: 70 * this.ratio,
      },
      {
        key: 'teachers',
        dataIndex: 'teachers',
        title: this.$t('common.teacher'),
        width: 70 * this.ratio,
      },
      ...this.dynamicHeader.map(item => {
        return {
          key: `periodGradeMap.${item.gradePeriodId}`,
          dataIndex: `periodGradeMap.${item.gradePeriodId}`,
          title: item.name,
          width: 40 * this.ratio,
        }
      }),
      {
        key: 'finalScore',
        dataIndex: 'finalScore',
        title: 'Final Score',
        width: 40 * this.ratio,
      },
    ]
  }

  private get excelColumns(): Array<any> {
    return [
      {
        key: 'student',
        title: this.$t('myClass.name'),
        dataIndex: 'student',
        width: 40,
      },
      {
        key: 'studentNum',
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 25,
        ellipsis: true,
      },
      {
        key: 'grade',
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        width: 20,
      },
      {
        key: 'className',
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        key: 'advisory',
        dataIndex: 'advisory',
        title: this.$t('common.advisory'),
        width: 30,
      },
      {
        key: 'subject',
        dataIndex: 'subject',
        title: this.$t('common.subject'),
        width: 30,
      },
      {
        key: 'course',
        dataIndex: 'course',
        title: this.$t('common.subjectClass'),
        width: 30,
      },
      {
        key: 'teachers',
        dataIndex: 'teachers',
        title: this.$t('common.teacher'),
        width: 30,
      },
      ...this.dynamicHeader.map(item => {
        return {
          key: item.gradePeriodId,
          dataIndex: item.gradePeriodId,
          title: item.name,
          width: 15,
        }
      }),
      {
        key: 'finalScore',
        dataIndex: 'finalScore',
        title: 'Final Score',
        width: 15,
      },
    ]
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    this.data = []
    this.dynamicHeader = []
    let {
      schoolYearId,
      campusId,
      subjectId,
      courseId,
      classId,
      houseGroupId,
      teacherId,
      studentName,
      studentNum,
    } = this.filter
    let request = {
      schoolYearId,
      campusId,
      subjectId,
      courseId,
      classId,
      houseGroupId,
      teacherId,
      studentName: studentName.trim() ? studentName.trim() : undefined,
      studentNum: studentNum.trim() ? studentNum.trim() : undefined,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    this.loading = true
    TaskGradeController.getStudentGradeFinal(request)
      .then(res => {
        this.dynamicHeader = res.data.gradePeriods
        this.data = res.data.data.items
        this.pagination.total = res.data.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 1000)

  private refresh(): void {
    this.getData(this.pagination)
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSchoolYearRuleList(),
      DropDownController.getCampusList(),
      DropDownController.getSubjectListForAttendance(),
      AttendanceController.getKGClassList(),
      DropDownController.getHouseGroupList(),
      TeacherController.getListAll(),
    ])
      .then(res => {
        this.schoolYears = res[0].data
        this.campuses = res[1].data.filter(
          item => item.value.indexOf('初中') !== -1 || item.value.indexOf('高中') !== -1
        )
        this.subjects = res[2].data
        this.homerooms = clearEmptyArray(
          res[3].data.filter(item => item.extraValue === '1232' || item.extraValue === '1233')
        )
        this.houses = res[4].data
        this.teachers = res[5].data
        if (this.schoolYears[0]) {
          this.$set(this.filter, 'schoolYearId', this.schoolYears[0].key)
        }
        if (this.campuses[0]) {
          this.$set(this.filter, 'campusId', this.campuses[0].key)
          CourseController.getCourseBySectionAndSubject(
            this.filter.campusId,
            this.filter.schoolYearId,
            this.filter.subjectId
          )
            .then(res => {
              this.courses = res.data
            })
            .catch(err => {
              console.error(err)
            })
        }
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private reset(): void {
    this.filter = {
      schoolYearId: this.schoolYears[0].key,
      campusId: this.campuses[0].key,
      subjectId: [],
      courseId: undefined,
      classId: undefined,
      houseGroupId: undefined,
      teacherId: undefined,
      studentName: '',
      studentNum: '',
    }
    this.getData()
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(this.pagination)
  }

  private async exportExcel(): Promise<void> {
    this.exportLoading = true

    let {
      schoolYearId,
      campusId,
      subjectId,
      courseId,
      classId,
      houseGroupId,
      teacherId,
      studentName,
      studentNum,
    } = this.filter
    let request = {
      schoolYearId,
      campusId,
      subjectId,
      courseId,
      classId,
      houseGroupId,
      teacherId,
      studentName: studentName.trim() ? studentName.trim() : undefined,
      studentNum: studentNum.trim() ? studentNum.trim() : undefined,
      pageCurrent: 1,
      pageSize: 99999,
    }

    const res = await TaskGradeController.getStudentGradeFinal(request)

    const file = exportXlsx(
      this.excelColumns,
      res.data.data.items.map(item => {
        return {
          ...item,
          ...item.periodGradeMap,
        }
      })
    )
    const ws = file.getWorksheet(1)

    ws.eachRow(row => {
      row.height = 30
      row.eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
      })
    })

    await writeFile(file, 'Secondary Grading Form.xlsx')
    this.exportLoading = false
  }

  private changeSchoolYear(value): void {
    this.$set(this.filter, 'courseId', undefined)
    CourseController.getCourseBySectionAndSubject(
      this.filter.campusId,
      value,
      this.filter.subjectId
    )
      .then(res => {
        this.courses = res.data
      })
      .catch(err => {
        console.error(err)
      })
    this.fetchData()
  }

  private changeCampus(value): void {
    this.$set(this.filter, 'courseId', undefined)
    CourseController.getCourseBySectionAndSubject(
      value,
      this.filter.schoolYearId,
      this.filter.subjectId
    )
      .then(res => {
        this.courses = res.data
      })
      .catch(err => {
        console.error(err)
      })
    this.fetchData()
  }

  private changeSubject(value): void {
    this.$set(this.filter, 'courseId', undefined)
    CourseController.getCourseBySectionAndSubject(
      this.filter.campusId,
      this.filter.schoolYearId,
      value
    )
      .then(res => {
        this.courses = res.data
      })
      .catch(err => {
        console.error(err)
      })
    this.fetchData()
  }
}
