




























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  StudentController,
  AttendanceController,
} from '@/services/request.service'
import moment from 'moment'
import filterGroup from '@/components/filterGroup'
import DetailModal from './DetailModal.vue'
import { i18n } from '@/i18n/i18n'
const operations = {
  grade: function(condition) {
    const { start, end } = condition
    return AttendanceController.getSectionStatistics(start, end)
  },
  advisory: function(condition) {
    const { start, end } = condition
    return AttendanceController.getHouseStatistics(start, end)
  },
  subjectClass: function(condition) {
    const { start, end, subjectId } = condition
    if (!subjectId) return new Promise((resolve, reject) => reject('暂无数据'))
    return AttendanceController.getCourseStatistics(start, subjectId)
  },
  classPeriod: function(condition) {
    const { start, end, sectionId } = condition
    if (!sectionId) return new Promise((resolve, reject) => reject('暂无数据'))
    return AttendanceController.getClassPeriodStatistics(start, end, sectionId)
  },
  dormitory: function(condition) {
    const { start, end, period } = condition
    return AttendanceController.getDormitoryStatistics(start, end, period)
  },
  selfStudy: function(condition) {
    const { start, end } = condition
    return AttendanceController.getSSRStatistics(start, end)
  },
  class: function(condition) {
    const { start, end, campusId } = condition
    if (!campusId) return new Promise((resolve, reject) => reject('暂无数据'))
    return AttendanceController.getClassStatistics(start, end, campusId)
  },
}

@Component({
  components: {
    filterGroup,
    DetailModal,
  },
})
export default class StatViewer extends Vue {
  private filter: any = {
    date: [moment(), moment()],
    subjectId: undefined,
    period: 'am',
    sectionId: undefined,
    campusId: undefined,
  }
  private data: Array<any> = []
  // private totalData: any = {}
  private subjects: Array<any> = []
  private loading: boolean = false
  private grades: any = []
  private campuses: any = []
  private detailInfo: any = {}
  private detailVis: any = false
  private kinds: Array<any> = []

  private disabledDate(current): any {
    return (
      current &&
      (current < moment(this.currentSchoolYear.startTime).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private get period(): any {
    return [
      {
        key: 'am',
        label: 'AM',
      },
      {
        key: 'pm',
        label: 'PM',
      },
      {
        key: 'eve',
        label: 'EVE',
      },
    ]
  }

  private get columns(): any {
    let dynamicColumns = this.kinds
      .map(item => [
        {
          key: `count_${item.attendanceKindId}`,
          title: this.locale === 'zh' ? item.name : item.enName,
          colSpan: 2,
          scopedSlots: { customRender: 'commonBlock' },
          typeId: item.attendanceKindId,
          attend: item.attend,
        },
        {
          key: `rate_${item.attendanceKindId}`,
          colSpan: 0,
          scopedSlots: { customRender: 'rateBlock' },
          typeId: item.attendanceKindId,
          attend: item.attend,
        },
      ])
      .flat()
    return [
      {
        key: 'dimension',
        title: this.$t('common.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'attendantNums',
        title: this.$t('attendance.rate'),
        colSpan: 2,
        scopedSlots: { customRender: 'attendanceBlock' },
      },
      {
        key: 'attendantRate',
        colSpan: 0,
        scopedSlots: { customRender: 'rateBlock' },
      },
    ]
      .concat(dynamicColumns)
      .flat()
      .filter(item => item)
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get type(): any {
    let tmpType = this.$route.params.statType
    return this.$route.params.statType
  }

  @Watch('type', { immediate: true })
  private onTypeChange(val): void {
    if (!val) return
    this.reset()
    if (['subjectClass', 'classPeriod', 'class'].includes(val)) {
      this.getDropDownInfo()
    } else {
      this.getData()
    }
    this.$route.meta.routerName = `${val}Stat`
  }

  private getDimension(record): any {
    return this.locale === 'zh' ? record.dimension : record.enDimension || record.dimension
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSectionListForClassPeriodStatistics(),
      DropDownController.getSubjectListForAttendance(),
      DropDownController.getCampusList(),
    ])
      .then(res => {
        this.grades = res[0].data
        this.subjects = res[1].data
        this.campuses = res[2].data.filter(item => item.extraValue)
        if (this.type === 'subjectClass' && this.subjects[0]) {
          this.$set(this.filter, 'subjectId', this.subjects[0]?.key)
        } else if (this.type === 'classPeriod' && this.grades[0]) {
          this.$set(this.filter, 'sectionId', this.grades[0]?.key)
        } else if (this.type === 'class' && this.campuses[0]) {
          this.$set(this.filter, 'campusId', this.campuses[0]?.key)
        }
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    this.data = []
    this.loading = true
    const { date, sectionId, period, campusId } = this.filter
    let condition = {
      start: date?.[0]?.valueOf(),
      end: date?.[1]?.valueOf(),
      sectionId,
      period,
      campusId,
    }
    operations[this.type](condition)
      .then(res => {
        let { statistics, kinds } = res.data

        this.kinds = kinds
        this.data = statistics.map((item, index) => {
          let { statisticDetail } = item
          let dynamicData = this.kinds
            .map(it => it.attendanceKindId)
            .map(it => {
              let result = {}
              result[`count_${it}`] = statisticDetail[it] ? statisticDetail[it].count : ''
              result[`rate_${it}`] = statisticDetail[it] ? statisticDetail[it].rate : ''
              return result
            })
          dynamicData = Object.assign({}, ...dynamicData)
          return {
            ...item,
            ...dynamicData,
            boldFlag: index === statistics.length - 1,
          }
        })
        // this.totalData = rest
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.loading = false))
  }

  private reset(): void {
    this.filter = {
      date: [moment(), moment()],
      subjectId: undefined,
      period: 'am',
      sectionId: undefined,
      campusId: '',
    }
    // this.totalData = {}
    this.data = []
    this.subjects = []
    this.loading = false
  }

  private viewDetail(record, key): void {
    if (!key) return
    const { date, sectionId, period, campusId } = this.filter
    const kind = this.kinds.find(item => item.attendanceKindId === key)
    const realKey = kind.value
    this.detailInfo = {
      title: this.$t('attendance.statDetailTitle', {
        key: kind[this.locale === 'zh' ? 'name' : 'enName'],
      }),
      date,
      sectionId,
      period,
      status: realKey,
      id: record.id,
      type: this.type,
      campusId,
    }
    this.detailVis = true
  }
}
