


































import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import { AttendanceController } from '@/services/request.service'
import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'
const detailReq = {
  grade: function(condition) {
    const { start, end, pageCurrent, pageSize, id, status } = condition
    return AttendanceController.getSectionAttendanceDetail(
      pageCurrent,
      pageSize,
      start,
      end,
      status,
      id
    )
  },
  advisory: function(condition) {
    const { start, end, pageCurrent, pageSize, id, status } = condition
    return AttendanceController.getHouseAttendanceDetail(
      pageCurrent,
      pageSize,
      start,
      end,
      status,
      id
    )
  },
  classPeriod: function(condition) {
    const { start, end, pageCurrent, pageSize, id, status, sectionId } = condition
    return AttendanceController.getClassPeriodAttendanceDetail(
      pageCurrent,
      pageSize,
      start,
      end,
      sectionId,
      status,
      id
    )
  },
  dormitory: function(condition) {
    const { start, end, period, pageCurrent, pageSize, id, status } = condition
    return AttendanceController.getDormitoryAttendanceDetail(
      pageCurrent,
      pageSize,
      start,
      end,
      status,
      id,
      period
    )
  },
  selfStudy: function(condition) {
    const { start, end, pageCurrent, pageSize, id, status } = condition
    return AttendanceController.getSSRAttendanceDetail(
      pageCurrent,
      pageSize,
      start,
      end,
      status,
      id
    )
  },
  class: function(condition) {
    const { start, end, pageCurrent, pageSize, id, status, campusId } = condition
    return AttendanceController.getClassAttendanceDetail(
      pageCurrent,
      pageSize,
      start,
      end,
      status,
      id,
      campusId
    )
  },
}

@Component({
  components: {
    FlexTooltip,
  },
})
export default class DetailModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  // 接受父组件的值
  @Prop() private detailInfo!: any

  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private data: any = []

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'date',
        title: this.$t('common.date'),
        ellipsis: true,
        width: 120,
        scopedSlots: { customRender: 'date' },
      },
      ['advisory', 'classPeriod', 'grade', 'class'].includes(this.detailInfo?.type) && {
        dataIndex: 'session',
        width: this.detailInfo?.type === 'classPeriod' ? 120 : 80,
        title: this.$t(
          `attendance.${this.detailInfo?.type === 'classPeriod' ? 'course' : 'session'}`
        ),
        scopedSlots: { customRender: 'session' },
      },
      this.detailInfo?.type === 'dormitory' && {
        key: 'period',
        width: 80,
        title: this.$t('attendanceReport.period'),
        scopedSlots: { customRender: 'period' },
      },
      {
        dataIndex: 'student',
        title: this.$t('common.student'),
      },
      {
        dataIndex: 'studentNum',
        width: 120,
        title: this.$t('common.studentId'),
      },
      {
        dataIndex: 'className',
        width: 150,
        title: this.$t('common.homeroom'),
      },
      {
        dataIndex: 'houseName',
        width: 150,
        title: this.$t('common.advisory'),
      },
      this.detailInfo?.type === 'dormitory' && [
        {
          dataIndex: 'floor',
          width: 100,
          title: this.$t('dormitory.floor'),
        },
        {
          dataIndex: 'dormitory',
          width: 100,
          title: this.$t('dormitory.room'),
        },
      ],
      this.detailInfo?.type === 'selfStudy' && {
        dataIndex: 'selfStudyRoom',
        width: 100,
        title: this.$t('common.studyRoom'),
      },
    ]
      .flat()
      .filter(item => item)
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.$nextTick(() => {
        this.getData()
      })
    }
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    const { date, sectionId, period, status, id, type, campusId } = this.detailInfo
    this.data = []
    this.loading = true
    let condition = {
      start: date?.[0]?.valueOf(),
      end: date?.[1]?.valueOf(),
      sectionId,
      period,
      status,
      id,
      campusId,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    detailReq[type](condition)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  private handleTableChange(pagination): any {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(this.pagination)
  }
}
