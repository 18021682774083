











































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  CourseController,
  AttendanceController,
  ClassPeriodController,
  SsRoomAttendanceController,
} from '@/services/request.service'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import AddRemark from './AddRemark.vue'
import EleStudentDetail from './StudentDetail.vue'
import PreStudentDetail from '@/views/Attendance/KindergartenAtten/components/StudentDetail.vue'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { clearEmptyArray } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import { toPage, Debounce } from '@/utils/utils'
import { i18n } from '@/i18n/i18n'
import { calcBg, calcSymbol } from '@/utils/utils'
import { statusList } from '@/constant/constant'
import { createPagination } from '@/constant/constant'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    AddRemark,
    EleStudentDetail,
    PreStudentDetail,
    TableTotal,
    FlexTooltip,
    filterGroup,
    SvgIcon,
  },
})
export default class StudyRoomAtten extends Vue {
  private filter: any = {
    date: moment(),
    selfStudyRoomId: undefined,
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private data: Array<any> = []
  private remarkVisible = false
  private record: any = {}
  private studentId = 0
  private currentPeriod = ''
  private lastPeriod = ''
  private studentName = ''
  private detailVisible = false
  private confirmVisible = false
  private dateStart = moment()
  private pmVisible = false
  private amVisible = false
  private options = []
  private moment = moment
  private weekViewer: boolean = false
  private dynamicHeader: any = []
  private windowH: any = 696
  private calcBg = calcBg
  private calcSymbol = calcSymbol
  private studentCampus = ''
  private detailModify = false
  private studentAttenType = 'full_day'

  private studyRoomList: Array<any> = []

  @Watch('detailVisible')
  private onVisChange(val) {
    if (!val && this.detailModify) {
      this.refresh()
    }
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get statusList(): any {
    return (this.$store.state.attendanceStatusList.length
      ? this.$store.state.attendanceStatusList
      : statusList
    ).filter(item => item !== 'examAbsent')
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    let weekCol = [
      {
        key: 'name',
        title: this.$t('attendance.name'),
        ellipsis: true,
        width: 220,
        fixed: 'left',
        scopedSlots: { customRender: 'fixedName' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'gender',
        width: 70,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('myClass.student.gender'),
        scopedSlots: { customRender: 'fixedGender' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'className',
        width: 80,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('attendance.class'),
        scopedSlots: { customRender: 'commonFix' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'studentNum',
        width: 100,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('attendance.studentId'),
        scopedSlots: { customRender: 'commonFix' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
    ]
    if (this.weekViewer) {
      let dynamicCol = this.dynamicHeader.map(item => ({
        dataIndex: item.key,
        key: item.key,
        date: item.date,
        align: 'center',
        title: `${moment(item.date).format('MM.DD')}\n${item.timeSegment}`,
        scopedSlots: { customRender: 'dynamicColumns' },
        customHeaderCell: () => ({ style: { whiteSpace: 'pre-line' } }),
        customCell: (record, index) => {
          return {
            style: { background: calcBg(record[item.key]), cursor: 'pointer' },
            class: 'dynamic-cell',
          }
        },
      }))
      weekCol = weekCol.concat(dynamicCol)
    }
    return this.weekViewer
      ? weekCol
      : [
          {
            key: 'name',
            title: this.$t('attendance.name'),
            ellipsis: true,
            scopedSlots: { customRender: 'name' },
          },
          {
            dataIndex: 'gender',
            width: 130 * this.ratio,
            title: this.$t('myClass.student.gender'),
            scopedSlots: { customRender: 'gender' },
          },
          {
            dataIndex: 'className',
            key: 'class',
            width: 200 * this.ratio,
            ellipsis: true,
            title: this.$t('attendance.class'),
          },
          {
            dataIndex: 'houseName',
            key: 'house',
            width: 200 * this.ratio,
            title: this.$t('attendance.house'),
          },
          {
            dataIndex: 'studentNum',
            key: 'studentId',
            width: 150 * this.ratio,
            title: this.$t('attendance.studentId'),
          },
          {
            key: 'attendance',
            slots: { title: 'attendance' },
            width: 250 * this.ratio,
            scopedSlots: { customRender: 'currentAttendance' },
          },
          {
            key: 'currentRemark',
            width: 120 * this.ratio,
            title: this.$t('attendance.remark'),
            scopedSlots: { customRender: 'currentRemark' },
          },
        ].flat()
  }

  private created() {
    this.getDateStart()
    this.getStudyRoomList()
  }

  private get attendanceDisabled(): any {
    return (
      !this.operationAuths.includes('2007') ||
      !this.data.length ||
      this.filter.date.isAfter(moment().endOf('day'))
    )
  }

  private getDateStart(): any {
    AttendanceController.getEditStart()
      .then(res => {
        this.dateStart = moment(res.data)
      })
      .catch(err => console.log(err))
  }

  private getStudyRoomList() {
    DropDownController.getSsRoomList().then(res => {
      this.studyRoomList = res.data
      const id = Number(this.$route.query.id)
      if (id) {
        this.filter.selfStudyRoomId = id
      } else {
        this.filter.selfStudyRoomId =
          this.studyRoomList.length > 0 ? this.studyRoomList[0].key : undefined
      }
      this.getRightData()
    })
  }

  private disabledDate(current): any {
    return (
      current &&
      (current < moment(this.dateStart).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  public getRightData(pagination?: any) {
    this.weekViewer ? this.getWeekData(pagination) : this.getData(pagination)
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    const { date, selfStudyRoomId } = this.filter
    if (!this.filter.selfStudyRoomId) return
    this.loading = true
    const request: any = {
      date: date.valueOf(),
      selfStudyRoomId,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    SsRoomAttendanceController.getDailyAttendances(request)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private getWeekData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    this.dynamicHeader = []
    const { date, selfStudyRoomId } = this.filter
    if (!this.filter.selfStudyRoomId) return
    const request: any = {
      date: this.filter.date.valueOf(),
      selfStudyRoomId,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    this.loading = true
    SsRoomAttendanceController.getWeeklyAttendances(request)
      .then(res => {
        this.dynamicHeader = res.data.weekDays
        this.data = res.data.attendances.items.map(item => ({
          ...item,
          ...item.attendanceStatusMap,
        }))
        this.pagination.total = res.data.attendances.totalItem
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.weekViewer ? this.getWeekData(this.pagination) : this.getData(this.pagination)
  }

  private refresh(): void {
    this.weekViewer ? this.getWeekData(this.pagination) : this.getData(this.pagination)
  }

  private setAttendance(key, studentId, type): void {
    const request = {
      date: moment(this.filter.date).valueOf(),
      type,
      studentId,
      status: key,
    }
    SsRoomAttendanceController.update(request).then(res => {
      let data = cloneDeep(this.data)
      data.forEach(item => {
        if (item.studentId === studentId) {
          item[type] = key
        }
      })
      this.data = cloneDeep(data)
    })
  }

  private setWeekAttendance(key, record, colKey): void {
    const day = this.dynamicHeader.find(item => item.key === colKey)
    const studentId = record.studentId
    const request = {
      date: day.date,
      type: day.timeSegment,
      studentId,
      status: key,
    }
    SsRoomAttendanceController.update(request)
      .then(res => {
        this.$message.success(this.$tc('common.attendanceSuccess'))
        record[colKey] = key
      })
      .catch(err => console.log(err))
  }

  private setAttendanceBatch(type): void {
    const request: any = {
      date: moment(this.filter.date).valueOf(),
      type,
      studentIds: this.data.map(item => item.studentId),
      status: 'intime',
    }
    SsRoomAttendanceController.batchUpdate(request)
      .then(res => {
        this.$message.success(this.$tc('common.attendanceSuccess'))
      })
      .finally(() => this.refresh())
  }

  private addRemark(record, filed): void {
    this.record = record
    this.$set(record, 'amOrPm', filed)
    this.remarkVisible = true
  }

  private viewDetail(studentId, studentName, campus, attendanceType): void {
    this.studentCampus = campus
    this.studentAttenType = attendanceType
    this.$nextTick(() => {
      this.studentId = studentId
      this.studentName = studentName
      this.detailVisible = true
      this.detailModify = false
    })
  }

  private confirmVisibleChange(visible): void {
    if (!visible) {
      this.confirmVisible = false
      return
    }
    if (this.operationAuths.includes('2007')) {
      this.confirmVisible = true
    }
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private reset() {
    this.data = []
    this.weekViewer = false
    this.currentPeriod = ''
    this.lastPeriod = ''
    this.studentCampus = '1230'
    this.studentAttenType = 'full_day'
    this.filter = {
      date: moment(),
      floor: undefined,
      dormitory: undefined,
    }
    this.pagination = createPagination({
      showTotal: total => i18n.t('common.pageTotal', { total }),
    })
    this.options = []
  }

  private pmVisibleChange(visible): void {
    if (!visible) {
      this.pmVisible = false
      return
    }
    if (this.$store.state.operationAuths.includes('2007')) {
      this.pmVisible = true
    }
  }

  private amVisibleChange(visible): void {
    if (!visible) {
      this.amVisible = false
      return
    }
    if (this.$store.state.operationAuths.includes('2007')) {
      this.amVisible = true
    }
  }

  private changeViewer(): void {
    this.weekViewer = !this.weekViewer
    this.weekViewer ? this.getWeekData() : this.getData()
  }

  private mounted(): any {
    setTimeout(() => {
      this.windowH = (this.$refs.attendanceViewer as Element)?.getBoundingClientRect().height || 696
    }, 0)
    window.addEventListener('resize', this.onWindowResize)
    this.$once('hook:beforeDestory', () => {
      window.removeEventListener('resize', this.onWindowResize)
    })
  }

  @Debounce(500)
  private onWindowResize(): void {
    this.windowH = (this.$refs.attendanceViewer as Element)?.getBoundingClientRect().height || 696
  }
}
