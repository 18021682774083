




























import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class StudentAttendance extends Vue {
  private emptyAuth: any = false

  private get type(): any {
    if (this.$route.name === 'kindergartenAtten') return 'class'
    if (this.$route.name === 'dormitoryAtten') return 'dormitory'
    if (this.$route.name === 'studyRoomAtten') return 'studyRoom'
    return this.$route.params.attendanceType || ''
  }

  private get menuAuth(): any {
    return this.$store.state.userAuths || {}
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'attendanceViewer' && !to.params.attendanceType) {
      this.redirectTo(this.menuAuth)
    } else if (to.name === 'attendanceViewer' && to.params.attendanceType === 'class') {
      this.$router.replace({ name: 'kindergartenAtten' }).catch(err => {})
    }
  }

  @Watch('menuAuth', { deep: true, immediate: true })
  private onAuthChange(value): void {
    this.emptyAuth = false
    if (
      (this.type === 'class' && !(value.classAtten || value.houseGroupTeacher)) ||
      (this.type === 'subjectClass' && !(value.courseTeacher || value.courseManager)) ||
      (this.type === 'advisory' && !(value.houseGroupTeacher || value.houseTeacher)) ||
      (this.type === 'CCAClass' && !value.ccaCourseTeacher) ||
      (this.type === 'dormitory' && !value.dormitory) ||
      (this.type === 'selfStudyRoom' && !value.selfStudyRoom) ||
      this.type === 'noAuth'
    ) {
      this.redirectTo(value)
    }
  }

  private changeType({ item, key, keyPath }): void {
    if (key === 'class') {
      this.$router.push({ name: 'kindergartenAtten' }).catch(err => {})
    } else if (key === 'dormitory') {
      this.$router.push({ name: 'dormitoryAtten' }).catch(err => {})
    } else if (key === 'studyRoom') {
      this.$router.push({ name: 'studyRoomAtten' }).catch(err => {})
    } else {
      this.$router
        .push({ name: 'attendanceViewer', params: { attendanceType: key } })
        .catch(err => {})
    }
  }

  private redirectTo(value): void {
    if (value.classAtten) {
      this.$router
        .replace({ name: 'kindergartenAtten', query: { id: this.$route.query.id } })
        .catch(err => {})
    } else if (value.courseTeacher || value.courseManager) {
      this.$router
        .replace({
          name: 'attendanceViewer',
          params: { attendanceType: 'subjectClass' },
          query: { id: this.type === 'subjectClass' ? this.$route.query.id : undefined },
        })
        .catch(err => {})
    } else if (value.houseGroupTeacher || value.houseTeacher) {
      this.$router
        .replace({
          name: 'attendanceViewer',
          params: { attendanceType: 'advisory' },
          query: { id: this.type === 'advisory' ? this.$route.query.id : undefined },
        })
        .catch(err => {})
    } else if (value.ccaCourseTeacher) {
      this.$router
        .replace({
          name: 'attendanceViewer',
          params: { attendanceType: 'CCAClass' },
          query: { id: this.type === 'CCAClass' ? this.$route.query.id : undefined },
        })
        .catch(err => {})
    } else if (value.dormitory) {
      this.$router.replace({ name: 'dormitoryAtten' }).catch(err => {})
    } else if (value.studyRoom) {
      this.$router.replace({ name: 'studyRoomAtten' }).catch(err => {})
    } else {
      this.$router
        .replace({ name: 'attendanceViewer', params: { attendanceType: 'noAuth' } })
        .catch(err => {})
      this.emptyAuth = true
    }
  }
}
