import { render, staticRenderFns } from "./StudentAttendance.vue?vue&type=template&id=71218a96&scoped=true&"
import script from "./StudentAttendance.vue?vue&type=script&lang=ts&"
export * from "./StudentAttendance.vue?vue&type=script&lang=ts&"
import style0 from "./StudentAttendance.vue?vue&type=style&index=0&id=71218a96&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71218a96",
  null
  
)

export default component.exports